<template>
  <v-app-bar
    class="main-header"
    height="64"
    fixed
    color='primary'
    dark>

    <v-toolbar-title>{{ headerTitle }}</v-toolbar-title>
    <v-spacer></v-spacer>

    <!--<v-menu-->
      <!--offset-y-->
      <!--bottom-->
      <!--nudge-bottom="10"-->
      <!--left>-->
        <!--<template v-slot:activator="{ on, attrs }">-->
          <!--<v-btn-->
            <!--@click="notificationsBadge ? notificationsBadge = !notificationsBadge : ''"-->
            <!--v-bind="attrs"-->
            <!--v-on="on"-->
            <!--style="font-size: 28px"-->
            <!--icon-->
            <!--class="mr-2">-->
              <!--<v-badge-->
                <!--:value="notificationsBadge"-->
                <!--color="error"-->
                <!--content="4"-->
                <!--overlap>-->
                  <!--<v-icon-->
                    <!--style="font-size: 28px"-->
                    <!--color="rgba(255, 255, 255, 0.35)">mdi-bell-outline</v-icon>-->
              <!--</v-badge>-->
          <!--</v-btn>-->
        <!--</template>-->
        <!--<v-list>-->
          <!--<v-list-item-group color="primary">-->
            <!--<v-list-item-->
              <!--v-for="(item, i) in notifications"-->
              <!--:key="i">-->
                <!--<v-list-item-icon class="mr-4 mb-1">-->
                  <!--<v-icon-->
                    <!--:color="item.color"-->
                    <!--v-text="item.icon">-->
                  <!--</v-icon>-->
                <!--</v-list-item-icon>-->
                <!--<v-list-item-content>-->
                  <!--<v-list-item-title v-text="item.text"></v-list-item-title>-->
                <!--</v-list-item-content>-->
            <!--</v-list-item>-->
          <!--</v-list-item-group>-->
        <!--</v-list>-->
    <!--</v-menu>-->

    <v-menu
      min-width="180"
      offset-y
      bottom
      left
      nudge-bottom="10">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-0"
            icon
            v-bind="attrs"
            v-on="on">
            <v-icon
              style="font-size: 28px"
              :color="config.light.iconColor">mdi-account</v-icon>
            </v-btn>
        </template>
        <v-list >
          <div class="text-h5 grey--text text--darken-3 px-4 pt-4">{{customerObj ? customerObj.profile.customer_name : ''}}</div>
          <div class="subtitle-2 primary--text font-weight-regular px-4">{{customerObj ? customerObj.profile.customer_phone : ''}}</div>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(item, i) in account"
              :key="i" :to="item.link" :target="item.target">
                <v-list-item-icon class="mr-4">
                  <v-icon
                    :color="item.color"
                    v-text="item.icon">
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title :color='config.light.textColor' v-text="item.text"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <div class="d-flex justify-center my-3">
            <v-btn
              width="80%"
              large
              outlined
              color="primary"
              class="text-capitalize"
              @click="logOut">Sign Out
            </v-btn>
          </div>
        </v-list>
    </v-menu>

  </v-app-bar>
</template>

<script>
import defaultSettings from '@/settings'
import {mapGetters} from 'vuex'
import config from '../../config';

  export default {
    name: 'Header',
    data: () => ({
      config,
      account: [
        { text: 'Dashboard', icon: 'mdi-home', color: 'textColor', link: '/dashboard', target:'_self'  },
        { text: 'Profile', icon: 'mdi-account', color: 'textColor', link: '/profile',target:'_self'  },
        { text: 'Q&A', icon: 'mdi-tooltip', color: 'textColor', link: '/qa', target:'_blank' },
        { text: 'Terms', icon: 'mdi-tooltip-text', color: 'textColor', link: '/terms', target:'_blank'   }
      ],
      notificationsBadge: true,
    }),
    computed: {
      ...mapGetters([
        'sidebar',
        'customerObj',
      ]),
      headerTitle() {
        return defaultSettings.title
      },
      notifications(){
        return this.customerObj.notifications.map((n)=>{
          let icon, text;
          const color = n.type === 'App\\Notifications\\RewardEarned' ? 'success' : 'warning';
          if(n.type === 'App\\Notifications\\RewardEarned'){
            if(n.data.type == 'loyaltypts'){
              icon = 'mdi-tag'; text = `You have rewarded with ${n.data.reward.points} points`;
            }
            if(n.data.type == 'voucher'){
              icon = 'mdi-ticket'; text = `You have rewarded with a voucher`;
            }
          }
          return { text: text, icon: icon, color: color};
        })
      },
    },
    methods: {
       toggleSideBar() {
        this.$store.dispatch('app/toggleSideBar')
      },
      async logOut() {
        await this.$store.dispatch('customer/logout')
        this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      }
    }
  };
</script>

<style src="./Header.scss" lang="scss"></style>
