import request from '@/plugins/request'

export function sendOTP(data) {
  return request({
    url: '/crm/event/sendotp',
    method: 'post',
    data
  })
}

export function confirmOTP(data) {
  return request({
    url: '/crm/event/confirmotp',
    method: 'post',
    data
  })
}

export function fetchEventData(eventID) {
  return request({
    url: '/crm/event/info/'+eventID,
    method: 'get'
  })
}

export function saveProfile(data) {
    return request({
        url: '/crm/event/profile',
        method: 'post',
        data
    })
}