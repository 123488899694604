<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">Dashboard</h1>

      </v-row>
      <v-row>

        <v-col lg="4" sm="6" cols="12">
          <div class="profile-page">
          <v-card class="user-card ma-1" min-height="282">
            <v-card-text class="pa-5 pt-9 fill-height">
              <v-row no-gutters class="fill-height">
                <v-col cols="12" sm="5" xl="6" class="d-flex justify-center align-center flex-column">
                  <div
                          class="avatar-wrapper">
                    <v-img
                            class="user-avatar"
                            contain
                            src="@/assets/user.png" style="width: 179px;"></v-img>
                  </div>
                  <v-chip
                          small
                          class="mr-2 mt-4 mb-4 mb-sm-0"
                          color="error"
                  >{{ customerMembership }}</v-chip>
                </v-col>
                <v-col cols="12" sm="7" xl="6" class="pl-0 pl-sm-4 d-flex flex-column align-center align-sm-start"  >
                  <p class="user-name greyMedium--text font-weight-regular">{{ customerName }}</p>
                  <p class="user-work greyTint--text ma-0">{{ customerPhone }}</p>
                  <v-row no-gutters class="pb-3">

                    <v-col class="my-auto" v-if="LoyaltyPercentage">
                        <span>Earn extra {{LoyaltyPercentage}}% points on every purchase</span>
                    </v-col>

                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
            </div>
        </v-col>

        <v-col lg="4" sm="6" cols="12">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-3">
              <p>VOUCHERS SUMMARY</p>
            </v-card-title>
            <v-card-text class="pa-6 pt-0" >
              <v-row no-gutters class="pb-5">
                <v-col cols="5" class="my-auto">
                  <span
                          class="font-weight-medium card-dark-grey"
                          style="font-size: 24px"
                  >{{ voucherBlockInfo.total }}</span
                  >
                </v-col>
                <v-col cols="6" >
                  <v-img src="@/assets/cinema.png" contain height="100"></v-img>
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-space-between pb-3">
                <v-col cols="4">
                  <div class="card-light-grey">Active</div>
                  <div class="text-h6 card-dark-grey font-weight-regular" >
                    {{ voucherBlockInfo.active }}
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="card-light-grey">Redeemed</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ voucherBlockInfo.redeemed }}
                  </div>
                </v-col>
                <v-col cols="4" xl="2">
                  <div class="text-right card-light-grey">Expired</div>
                  <div class="text-right text-h6 card-dark-grey font-weight-regular">
                    {{ voucherBlockInfo.expired }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col lg="4" sm="6" cols="12">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-3">
              <p>LOYALTY POINTS SUMMARY</p>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters class="pb-5">
                <v-col cols="5" class="my-auto">
                  <span
                          class="font-weight-medium card-dark-grey"
                          style="font-size: 24px"
                  >{{ pointBlockInfo.total }}</span
                  >
                </v-col>
                <v-col cols="6">
                  <v-img src="@/assets/rupee.png" contain height="100"></v-img>
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-space-between pb-3">
                <v-col cols="4">
                  <div class="card-light-grey">Available</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ pointBlockInfo.active }}
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="card-light-grey">Redeemed</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ pointBlockInfo.redeemed }}
                  </div>
                </v-col>
                <v-col cols="4" xl="2">
                  <div class="text-right card-light-grey">Expired</div>
                  <div class="text-right text-h6 card-dark-grey font-weight-regular">
                    {{ pointBlockInfo.expired }}
                  </div>
                </v-col>
              </v-row>
              <v-row justify="center" v-if="pointBlockInfo.near_expiry">
                <v-alert dense outlined type="error">You have <strong>{{ pointBlockInfo.near_expiry }}</strong> points expiring in the next 30 days</v-alert>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col lg="6" sm="6" md="6" cols="12">
          <v-card class="support-requests mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <p>VOUCHERS HISTORY</p>
              <v-spacer></v-spacer>
              <v-text-field
                      v-model="rewardedVouchers.search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
              ></v-text-field>

            </v-card-title>
            <v-card-text class="pa-0" >

              <v-data-table
                      dense
                      height="300"
                      disable-sort
                      :footer-props="footerProps"
                      :headers="rewardedVouchers.columns"
                      :items="rewardedVouchers.rows"
                      :search="rewardedVouchers.search"
                      :loading="rewardedVouchers.loading"
                      loading-text="Loading... Please wait"
                      class="elevation-1">

                <template v-slot:[`item.status`]="{ item }">
                  <v-chip small v-if="item.status === 'Active'" color="success" class="ma-2 ml-0">
                    {{item.status}}
                  </v-chip>

                  <v-chip small v-if="item.status === 'Expired'" color="warning" class="ma-2 ml-0">
                    {{item.status}}
                  </v-chip>

                  <v-chip small v-if="item.status === 'Redeemed'" color="secondary" class="ma-2 ml-0">
                    {{item.status}}
                  </v-chip>
                </template>


                <template v-slot:[`item.start_date`]="{ item }">
                  {{ $xutils.toDateString(item.start_date, 'dd/MM/yyyy') }}
                </template>

                <template v-slot:[`item.end_date`]="{ item }">
                  {{ $xutils.toDateString(item.end_date, 'dd/MM/yyyy') }}
                </template>

              </v-data-table>

             </v-card-text>
          </v-card>
        </v-col>

        <v-col lg="6" sm="6" md="6" cols="12" >
          <v-card class="support-requests mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <p>POINTS LEDGER</p>
              <v-spacer></v-spacer>

            </v-card-title>
            <v-card-text class="pa-0" >

              <v-timeline align-top dense >

                <v-virtual-scroll :items="pointsLedger.rows" :item-height="50" height="350">
                  <template v-slot:default="{ item }">

                  <v-timeline-item color="teal lighten-3" small>
                    <v-row class="pt-1">
                      <v-col>
                        <strong>{{ item.text }}</strong>
                        <div class="text-caption">{{ $xutils.toDateString(item.time, 'dd/MM/yyyy hh:ss A') }}</div>
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                    </template>
                  </v-virtual-scroll>

              </v-timeline>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="support-requests mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <p>ORDERS</p>
              <v-spacer></v-spacer>

            </v-card-title>
            <v-card-text class="pa-0">
              <v-data-table
                      dense
                      max-height="600"
                      disable-sort
                      :footer-props="footerProps"
                      :headers="purchaseHistory.columns"
                      :items="purchaseHistory.rows"
                      :options.sync="purchaseHistoryOptions"
                      :server-items-length="purchaseHistory.total"
                      :loading="purchaseHistory.loading"
                      loading-text="Loading... Please wait"
                      class="elevation-1">

                <template v-slot:[`item.crm_points_earn_burn`]="{ item }">
                  <v-chip small color="teal" dark v-if="item.crm_points_earned">{{ $fmtNumber.formatFixed(item.crm_points_earned) }}</v-chip>
                  &nbsp;
                  <v-chip small color="secondary" dark v-if="item.crm_points_redeemed">{{ $fmtNumber.formatFixed(item.crm_points_redeemed) }}</v-chip>
                </template>

                <template v-slot:[`item.crm_vouchers_earn_burn`]="{ item }">
                  <v-chip small color="teal" dark v-if="item.crm_vouchers_earned">{{ $fmtNumber.formatFixed(item.crm_vouchers_earned) }}</v-chip>
                  &nbsp;
                  <v-chip small color="secondary" dark v-if="item.crm_vouchers_redeemed">{{ $fmtNumber.formatFixed(item.crm_vouchers_redeemed) }}</v-chip>
                </template>

                <template v-slot:[`item.created_at`]="{ item }">
                  {{ $xutils.toDateString(item.created_at, 'dd/MM/yyyy hh:ss A') }}
                </template>

                <template v-slot:[`item.sale_amount`]="{ item }">
                  {{ $fmtNumber.formatFixed(item.sale_amount) }}
                </template>

                <template v-slot:[`item.invoice_bill`]="{ item }">
                  <a :href="item.invoice_bill" target="_blank"><v-icon color="teal">fa fa-file</v-icon></a>
                </template>

              </v-data-table>


            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
    </div>
  </v-container>
</template>

<script>

import {mapGetters} from 'vuex'
import { listPurchaseHistory, getVouchersHistory, getPointsLedger } from '@/api/user'


export default {
  name: "Dashboard",

  data() {
    return {
      pointsLedger:{
        loading: true,
        rows:[]
      },

      rewardedVouchers:{
        loading: true,
        columns:[
          { text: 'Reward', value: 'title' },
          { text: 'Voucher', value: 'code' },
          { text: 'Status', value: 'status' },
          { text: 'Redeem StartDate', value: 'start_date' },
          { text: 'Redeem EndDate', value: 'end_date' },
        ],
        rows:[]
      },

      purchaseHistory:{
        loading: true,
        columns:[
          { text: 'Bill Date', value: 'created_at' },
          { text: 'Bill Number', value: 'invoice_no' },
          { text: 'Store Name', value: 'location_name' },
          { text: 'Location', value: 'location_address' },
          { text: 'Bill Amount', value: 'sale_amount' , align:'end'},
          { text: 'Bill Invoice', value: 'invoice_bill', align:'center' },
          { text: 'Points (Earn | Burn)', value: 'crm_points_earn_burn' },
          { text: 'Vouchers (Earn | Burn)', value: 'crm_vouchers_earn_burn' },

        ],
        rows:[],
        total:0
      },
      purchaseHistoryOptions: {},
      footerProps:{
        itemsPerPageOptions : [20, 50, 100]
      },

    };
  },
  computed: {
    ...mapGetters([
      'customerObj',
    ]),
    LoyaltyPercentage(){
      return this.customerObj ? this.customerObj.profile.crm_membership.loyalty_plus_percentage : ''
    },
    customerPhone(){
      return this.customerObj ? this.customerObj.profile.customer_phone : ''
    },
    customerMembership(){
      return this.customerObj ? this.customerObj.profile.crm_membership.name : ''
    },
    customerName(){
      return this.customerObj ? this.customerObj.profile.customer_name : ''
    },
    voucherBlockInfo(){
      return this.customerObj ? this.customerObj.rewards_info.vouchers : []
    },
    pointBlockInfo(){
      return this.customerObj ? this.customerObj.rewards_info.points : []
    },
  },
  watch: {

    purchaseHistoryOptions: {
      handler () {
        this.getPurchaseHistoryFromApi()
      },
      deep: true,
    },
  },

  created(){

    getVouchersHistory().then(response => {
      this.rewardedVouchers.rows = response.apiData;
      this.rewardedVouchers.loading = false
    }).catch(err => {
      console.log(err)
    })


    getPointsLedger().then(response => {
      this.pointsLedger.rows = response.apiData;
      this.pointsLedger.loading = false
    }).catch(err => {
      console.log(err)
    })

  },
  methods: {

    getPurchaseHistoryFromApi() {

      this.purchaseHistory.loading = true
      listPurchaseHistory(this.purchaseHistoryOptions).then(response => {
        this.purchaseHistory.total = response.apiData.total
        this.purchaseHistory.rows = response.apiData.data;
        this.purchaseHistory.loading = false
      }).catch(err => {
        console.log(err)
      })
    },

  },
};
</script>

<style src="./Dashboard.scss" lang="scss"/>
