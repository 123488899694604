import Vue from 'vue';
import Vuex from 'vuex';

import getters from './getters'
import settings from './modules/settings'
import customer from './modules/customer'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    settings,
    customer
  },
  getters
  /*
  namespace: true,

  state: {
    drawer: true
  },
  mutations: {
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    }
  },
  actions: {
    TOGGLE_DRAWER({ commit }) {
      commit('toggleDrawer');
    }
  },
  getters: {
    DRAWER_STATE(state) {
      return state.drawer;
    }
  }*/
});
