import Vue from 'vue'
import App from './App.vue'
import router from './Routes'
import store from './store/index'
import vuetify from './plugins/vuetify'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en' // lang i18n

// set ElementUI lang to EN
Vue.use(ElementUI, { locale })


import '@/permission' // permission control

Vue.config.productionTip = false

import XEUtils from 'xe-utils'
const pluginUtil = {
    install() {
        Vue.xutils = XEUtils
        Vue.prototype.$xutils = XEUtils
    }
}
Vue.use(pluginUtil)

import fmtNumber from 'indian-number-format'
Object.defineProperty(Vue.prototype, '$fmtNumber', { value: fmtNumber })

new Vue({
  vuetify,
  router,
  render: h => h(App), store
}).$mount('#app')
