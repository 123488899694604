<template>
  <v-container fluid class="elements-page mt-4">
    <v-row justify="center">
      <v-col lg=6 cols=12>
        <v-card class="overflow-hidden mx-1">
          <v-card-title class="pa-5 pb-3">
            <p>Profile</p>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="pa-5 pt-0">

            <v-progress-linear v-if="loading" color="deep-purple accent-4" indeterminate rounded height="6"></v-progress-linear>

            <v-form
                    ref="form"
                    v-model="profileForm.valid"
                    lazy-validation>
              <v-row>
                <v-col cols="5" class="d-flex align-center">
                  <p class="fs-normal greyBold--text mb-0">Name</p>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                          v-model="profileForm.customer_name"
                          :rules="profileFormRules.nameRules"
                          label="Name"
                          required
                  ></v-text-field>
                </v-col>

                <v-col cols="5" class="d-flex align-center">
                  <p class="fs-normal greyBold--text mb-0">Gender</p>
                </v-col>
                <v-col cols="7">
                  <v-radio-group v-model="profileForm.customer_gender" row>
                    <v-radio label="Male" value="M"></v-radio>
                    <v-radio label="Female" value="F"></v-radio>
                  </v-radio-group>

                </v-col>

                <v-col cols="5" class="d-flex align-center">
                  <p class="fs-normal greyBold--text mb-0">DOB</p>
                </v-col>
                <v-col cols="7">
                  <v-menu
                          v-model="DOBDateMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto">
                    <template v-slot:activator="{ on }">
                      <v-text-field
                              v-model="profileForm.customer_dob"
                              label="Date"
                              readonly
                              v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                            v-model="profileForm.customer_dob"
                            no-title
                            @input="DOBDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="5" class="d-flex align-center">
                  <p class="fs-normal greyBold--text mb-0">Email</p>
                </v-col>
                <v-col cols="7"><v-text-field
                        v-model="profileForm.customer_email"
                        :rules="profileFormRules.emailRules"
                        label="E-mail"
                        required
                ></v-text-field></v-col>

                <!--<v-col cols="5" class="d-flex align-center">-->
                  <!--<p class="fs-normal greyBold&#45;&#45;text mb-0">Spouse Name</p>-->
                <!--</v-col>-->
                <!--<v-col cols="7">-->
                  <!--<v-text-field-->
                          <!--v-model="profileForm.spouse_name"-->
                          <!--label="Name"-->
                          <!--required-->
                  <!--&gt;</v-text-field>-->
                <!--</v-col>-->

                <!--<v-col cols="5" class="d-flex align-center">-->
                  <!--<p class="fs-normal greyBold&#45;&#45;text mb-0">Spouse Mobile</p>-->
                <!--</v-col>-->
                <!--<v-col cols="7">-->
                  <!--<v-text-field-->
                          <!--v-model="profileForm.spouseMobile"-->
                          <!--:rules="profileForm.spouseMobileRules"-->
                          <!--label="Number"-->
                          <!--required-->
                  <!--&gt;</v-text-field>-->
                <!--</v-col>-->


                <!--<v-col cols="5" class="d-flex align-center">-->
                  <!--<p class="fs-normal greyBold&#45;&#45;text mb-0">Spouse DOB</p>-->
                <!--</v-col>-->
                <!--<v-col cols="7">-->
                  <!--<v-menu-->
                          <!--v-model="spouseDOBDateMenu"-->
                          <!--:close-on-content-click="false"-->
                          <!--:nudge-right="40"-->
                          <!--transition="scale-transition"-->
                          <!--offset-y-->
                          <!--min-width="auto">-->
                    <!--<template v-slot:activator="{ on }">-->
                      <!--<v-text-field-->
                              <!--v-model="profileForm.spouse_dob"-->
                              <!--label="Date"-->
                              <!--readonly-->
                              <!--v-on="on"-->
                      <!--&gt;</v-text-field>-->
                    <!--</template>-->
                    <!--<v-date-picker-->
                            <!--v-model="profileForm.spouse_dob"-->
                            <!--no-title-->
                            <!--@input="spouseDOBDateMenu = false"-->
                    <!--&gt;</v-date-picker>-->
                  <!--</v-menu>-->
                <!--</v-col>-->


                <v-col cols="5" class="d-flex align-center">
                  <p class="fs-normal greyBold--text mb-0">Marriage Anniversary</p>
                </v-col>
                <v-col cols="7">
                  <v-menu
                          v-model="anniversaryDateMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto">
                    <template v-slot:activator="{ on }">
                      <v-text-field
                              v-model="profileForm.customer_anniversary"
                              label="Anniversary Date"
                              readonly
                              v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                            v-model="profileForm.customer_anniversary"
                            no-title
                            @input="anniversaryDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>


                <!--<v-col cols="5" class="d-flex align-center">-->
                  <!--<p class="fs-normal greyBold&#45;&#45;text mb-0">Child 1 DOB</p>-->
                <!--</v-col>-->
                <!--<v-col cols="7">-->
                  <!--<v-menu-->
                          <!--v-model="k1DOBDateMenu"-->
                          <!--:close-on-content-click="false"-->
                          <!--:nudge-right="40"-->
                          <!--transition="scale-transition"-->
                          <!--offset-y-->
                          <!--min-width="auto">-->
                    <!--<template v-slot:activator="{ on }">-->
                      <!--<v-text-field-->
                              <!--v-model="profileForm.child1_dob"-->
                              <!--label="Date"-->
                              <!--readonly-->
                              <!--v-on="on"-->
                      <!--&gt;</v-text-field>-->
                    <!--</template>-->
                    <!--<v-date-picker-->
                            <!--v-model="profileForm.child1_dob"-->
                            <!--no-title-->
                            <!--@input="k1DOBDateMenu = false"-->
                    <!--&gt;</v-date-picker>-->
                  <!--</v-menu>-->
                <!--</v-col>-->

                <!--<v-col cols="5" class="d-flex align-center">-->
                  <!--<p class="fs-normal greyBold&#45;&#45;text mb-0">Child 2 DOB</p>-->
                <!--</v-col>-->
                <!--<v-col cols="7">-->
                  <!--<v-menu-->
                          <!--v-model="k2DOBDateMenu"-->
                          <!--:close-on-content-click="false"-->
                          <!--:nudge-right="40"-->
                          <!--transition="scale-transition"-->
                          <!--offset-y-->
                          <!--min-width="auto">-->
                    <!--<template v-slot:activator="{ on }">-->
                      <!--<v-text-field-->
                              <!--v-model="profileForm.child2_dob"-->
                              <!--label="Date"-->
                              <!--readonly-->
                              <!--v-on="on"-->
                      <!--&gt;</v-text-field>-->
                    <!--</template>-->
                    <!--<v-date-picker-->
                            <!--v-model="profileForm.child2_dob"-->
                            <!--no-title-->
                            <!--@input="k2DOBDateMenu = false"-->
                    <!--&gt;</v-date-picker>-->
                  <!--</v-menu>-->
                <!--</v-col>-->

              </v-row>

              <v-checkbox
                      v-model="profileForm.crm_optout_promos"
                      false-value="N"
                      true-value="Y"
                      required>
                <template v-slot:label>
                  <small>Opt me out of all promotional messages and emails</small>
                </template>
              ></v-checkbox>

              <v-row justify="end">
                <v-btn
                        :disabled="!profileForm.valid"
                        color="primary"
                        class="button-shadow mr-4"
                        @click="updateForm">
                  Update
                </v-btn>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
   import { updateProfile } from '@/api/user'
  export default {
    name: 'Profile',
    data: () => ({
      loading:false,
      DOBDateMenu:false,
      anniversaryDateMenu:false,
      spouseDOBDateMenu:false,
      k1DOBDateMenu:false,
      k2DOBDateMenu:false,

      profileForm: {
        valid: true,
        customer_name: '',
        customer_email: '',
        customer_dob:'',
        customer_anniversary:'',
        customer_gender:'',
        crm_optout_promos:'',
        spouse_dob:'',
        spouse_name:'',
        child1_dob:'',
        child2_dob:'',
      },
      profileFormRules:{
        nameRules: [
          v => !!v || 'Name is required',
        ],
        emailRules: [
          v => !v || /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
      },
    }),
    created(){

      let customer = this.$store.getters.customerObj
      if(customer && 'profile' in customer){
        Object.keys(this.profileForm).forEach(field => {
            this.$set(this.profileForm, field, field in customer.profile ? customer.profile[field] : null)
        })

      }
    },
    methods: {
      updateForm() {
        let isValid = this.$refs.form.validate();
        if(isValid){
          this.loading = true;
          updateProfile(this.profileForm).then(() => {
            this.$message({
              message: 'Profile has updated successfully',
              type: 'success'
            })
            setTimeout(() => {
              location.reload()
            }, 1000);

          }, (err) => {
            console.log(err)
          }).finally(() => {
            this.loading = false

          })
        }
      },
      reset() {
        this.$refs.form.reset()
      },
    },
  }
</script>
