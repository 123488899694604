<template>
<v-app>
  
  <v-main>
    <v-container fluid class="elements-page">

      <v-row justify="center">
        <v-col lg=6 cols=12>
        <v-img src="@/assets/Smile-rewards-app_KLM.jpg" contain></v-img>
        </v-col>        
      </v-row>

      <v-row justify="center">
        <v-col lg=6 cols=12>
          <v-card class="overflow-hidden mx-1">
            <v-card-title class="pa-5 pb-3">
              <p>{{eventData.institute_name}}</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-5 pt-0">

              <v-progress-linear v-if="loading" color="deep-purple accent-4" indeterminate rounded height="6"></v-progress-linear>

              <v-form ref="form" v-model="profileForm.valid" lazy-validation>
                <v-row>
                  <v-col cols="5" class="d-flex align-center">
                    <p class="fs-normal greyBold--text mb-0">Name</p>
                  </v-col>
                  <v-col cols="7">
                    <v-text-field
                            v-model="profileForm.customer_name"
                            :rules="profileFormRules.nameRules"
                            label="Name"
                            required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="5" class="d-flex align-center">
                    <p class="fs-normal greyBold--text mb-0">Mobile</p>
                  </v-col>
                  <v-col cols="7">
                    <v-text-field
                            v-model="profileForm.customer_mobile"
                            :rules="profileFormRules.mobileRules"
                            label="Mobile"
                            required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="5" class="d-flex align-center">
                    <p class="fs-normal greyBold--text mb-0">Gender</p>
                  </v-col>
                  <v-col cols="7">
                    <v-radio-group 
                      v-model="profileForm.customer_gender" 
                      row>
                      <v-radio label="Male" value="M"></v-radio>
                      <v-radio label="Female" value="F"></v-radio>
                    </v-radio-group>

                  </v-col>

                  <v-col cols="5" class="d-flex align-center">
                    <p class="fs-normal greyBold--text mb-0">DOB</p>
                  </v-col>
                  <v-col cols="7">
                    <v-menu
                            v-model="DOBDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="profileForm.customer_dob"
                                label="Date"
                                readonly
                                v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                              v-model="profileForm.customer_dob"
                              no-title
                              @input="DOBDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="5" class="d-flex align-center">
                      <p class="fs-normal greyBold--text mb-0">Upload ID</p>
                    </v-col>
                    <v-col cols="7">
                      <v-file-input
                        :value="profileForm.photo_id"
                        @change="handleFileChange"
                        accept="image/*"
                        label="Upload an image"
                        prepend-icon="mdi-camera"
                        :rules="profileFormRules.imageRules"
                      ></v-file-input>
                      <v-img
                        v-if="imagePreview"
                        :src="imagePreview"
                        max-width="200"
                        max-height="200"
                        contain
                      ></v-img>
                    </v-col>
                  
                </v-row>

                <v-row justify="end">
                  <v-btn
                          :disabled="!profileForm.valid"
                          color="primary"
                          class="button-shadow mr-4"
                          @click="updateForm">
                    PROCEED
                  </v-btn>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
      <v-col cols="12" class="d-flex justify-center">
          <div class="primary--text">© 2024 Sai Silks (Kalamandir) Ltd. All rights reserved.</div>
      </v-col>
      </v-row>

    </v-container>
    </v-main>
  </v-app>
</template>

<style>
  .v-main__wrap{
    padding: 5px !important;
  }
  .row + .row{
    margin-top: 0px !important;
  }
</style>
<script>
  import { saveProfile, fetchEventData } from '@/api/event'
  export default {
    name: 'Profile',
    data: () => ({
      loading:false,
      DOBDateMenu:false,
      imagePreview: null,
      backgroundImage: 'url("../Smile-rewards-app_KLM.jpg")', // Replace with your image path
      backgroundSize: 'cover', // Try 'contain' if 'cover' is too zoomed
      backgroundPosition: 'center center', // Adjust this to change image position
      eventData:{},
      profileForm: {
        valid: true,
        customer_name: '',
        customer_mobile: '',
        customer_dob:'',
        customer_gender:'',
        photo_id:null,
      },
      profileFormRules:{
        nameRules: [
          v => !!v || 'Name is required',
        ],
        mobileRules: [
          v => !!v || 'Mobile is required',
          v => !v || /^[6789]\d{9}$/.test(v) || 'Mobile must be valid',
        ],
        genderRules: [
          v => !!v || 'Gender is required',
        ],
        dobRules: [
          v => !!v || 'DOB is required',
          v => (v && new Date(v) <= new Date(new Date().setFullYear(new Date().getFullYear() - 12))) || 'You must be at least 12 years old'
        ],
        imageRules: [
          v => !!v || 'This is required',
          v => !v || v.size < 5000000 || 'Image size should be less than 5 MB!',
        ],
      },
    }),
    created(){
      const eventCode = this.$route.params && this.$route.params.code
      this.fetchData(eventCode);
    },
    computed: {
      appStyles() {
        return {
          backgroundImage: this.backgroundImage,
          backgroundSize: this.backgroundSize,
          backgroundPosition: this.backgroundPosition,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          height: '100%',
          minHeight: '100vh',
        }
      },
    },
    methods: {

      fetchData(code) {
        this.loading = true
        fetchEventData(code).then(response => {
          Object.assign(this.eventData, response.apiData)
        }).catch(err => {
          console.log(err)
          this.$router.push({ path: '/login' });          
        }).finally(() => {
            this.loading = false
        })
      },
      updateForm() {
        let isValid = this.$refs.form.validate();
        if(isValid){

          this.loading = true;
          // Create a new FormData object to send the file
          const formData = new FormData();
          formData.append('event_code', this.eventData.event_code);
          Object.keys(this.profileForm).forEach(key => {
            if (key === 'photo_id' && this.profileForm[key]) {
              formData.append(key, this.profileForm[key]);
            } else {
              formData.append(key, this.profileForm[key]);
            }
          });

          saveProfile(formData).then(() => {
            this.$message({
              message: 'Profile has updated successfully',
              type: 'success'
            })
            setTimeout(() => {
              location.reload()
            }, 1000);

          }, (err) => {
            console.log(err)
          }).finally(() => {
            this.loading = false

          })
        }
      },

      reset() {
        this.$refs.form.reset()
        this.imagePreview = null
      },
      onFileChange(file) {
        if (file) {
          this.imagePreview = URL.createObjectURL(file)
        } else {
          this.imagePreview = null
        }
      },

      handleFileChange(file) {
      if (file) {
        this.resizeImage(file)
          .then(resizedFile => {
            this.profileForm.photo_id = resizedFile;
            this.imagePreview = URL.createObjectURL(resizedFile);
          })
          .catch(error => {
            console.error('Error resizing image:', error);
            this.$message({
              message: 'Failed to process the image',
              type: 'error'
            });
          });
      } else {
        this.profileForm.photo_id = null;
        this.imagePreview = null;
      }
    },

    resizeImage(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;
            
            if (width > height) {
              if (width > 1024) {
                height *= 1024 / width;
                width = 1024;
              }
            } else {
              if (height > 1024) {
                width *= 1024 / height;
                height = 1024;
              }
            }
            
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            
            canvas.toBlob(blob => {
              resolve(new File([blob], file.name, {
                type: 'image/jpeg',
                lastModified: Date.now()
              }));
            }, 'image/jpeg', 0.7); // Adjust quality here (0.7 = 70% quality)
          };
          img.onerror = reject;
          img.src = e.target.result;
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
  },
}
</script>
