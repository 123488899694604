<template>
  <v-app>
    <v-app-bar color="primary" app dark>
      <v-toolbar-title color="white">Q & A</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container >
        <div v-html="content"></div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

  import { getQA } from '@/api/user'
  export default {
    name: 'QA',
    data() {
      return {
        content :'Questions & Answers'
      }
    },
    created(){

      getQA().then(response => {
        this.content = response
      }).catch(err => {
        console.log(err)
      })
    },
  }

</script>
