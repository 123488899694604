import { sendOTP, confirmOTP, getInfo, logout } from '@/api/user'
import { getToken, setToken, removeToken } from '@/plugins/auth'
// import { resetRouter } from '@/Routes'

const getDefaultState = () => {
  return {
    token: getToken(),
    info: '',
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INFO: (state, data) => {
    state.info = data
  },
}

const actions = {
  
  sendOTP(context, payLoad) {
    return new Promise((resolve, reject) => {
      sendOTP(payLoad).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  confirmOTP({ commit }, payLoad) {

    return new Promise((resolve, reject) => {
      confirmOTP(payLoad).then(response => {
        const { apiData } = response
        commit('SET_TOKEN', apiData.token)
        setToken(apiData.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get customer info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const data = response.apiData

        if (!data) {
          return reject('Verification failed, please Login again.')
        }
        commit('SET_INFO', data)

        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout().then(() => {
        removeToken() // must remove  token  first
          commit('SET_TOKEN', '')
          commit('RESET_STATE')
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

