import request from '@/plugins/request'

export function sendOTP(data) {
  return request({
    url: '/crm/customer/sendotp',
    method: 'post',
    data
  })
}

export function confirmOTP(data) {
  return request({
    url: '/crm/customer/confirmotp',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: '/crm/customer/accountinfo',
    method: 'get'
  })
}

export function getVouchersHistory() {
    return request({
        url: '/crm/customer/vouchers',
        method: 'get'
    })
}

export function getPointsLedger() {
    return request({
        url: '/crm/customer/pointsledger',
        method: 'get'
    })
}



export function listPurchaseHistory(params) {
    return request({
        url: '/crm/customer/purchasehistory',
        method: 'get',
        params
    })
}

export function getTermsOfService() {
    return request({
        url: '/crm/customer/terms',
        method: 'get'
    })
}

export function getQA() {
    return request({
        url: '/crm/customer/qa',
        method: 'get'
    })
}


export function updateProfile(data) {
    return request({
        url: '/crm/customer/profile',
        method: 'post',
        data
    })
}

export function logout() {
  return request({
    url: '/crm/customer/logout',
    method: 'get'
  })
}
