<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
  };
</script>

<style src="./styles/app.scss" lang="scss"/>


