<template>
  <v-app>
    <v-app-bar color="primary" app dark>
      <v-toolbar-title color="white">TERMS OF SERVICE</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container >
        <div v-html="terms_of_service"></div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

  import { getTermsOfService } from '@/api/user'
  export default {
    name: 'Terms',
    data() {
      return {
        terms_of_service :''
      }
    },
    created(){

      getTermsOfService().then(response => {
        this.terms_of_service = response
      }).catch(err => {
        console.log(err)
      })
    },
  }

</script>
