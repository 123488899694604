<template>
  <v-app >
    <v-container fluid>

      <v-row no-gutters>
        <v-col cols="7" class="main-part d-none d-md-none d-lg-flex">
          <div class="d-flex">
            <v-img src="@/assets/sr.png" contain height="400"></v-img>
            <!--<p>{{ headerTitle }}</p>-->
          </div>
        </v-col>
        <v-col cols="12" lg="5" class="login-part d-flex align-center justify-center">
          <v-row no-gutters class="align-start">
            <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
              <div class="login-wrapper pt-md-4 pt-0">

                <div style="background-color:#18184c;color: white; border-radius:20px" class="d-lg-none pt-5">
                  <v-img src="@/assets/sr.png" contain height="100"></v-img>
                  <p align="center" class="v-toolbar__title">&nbsp;</p>
                </div>

                <v-card class="elevation-12">

                  <v-toolbar dark color="primary">
                    <v-toolbar-title>SIGN IN</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-form ref="form" v-on:submit.prevent>
                      <v-text-field
                              ref="mobileField"
                              v-model="mobile"
                              prepend-icon="fa-mobile"
                              :append-icon="otpSent && !loading ? 'fa-edit' : ''"
                              :readonly="otpSent"
                              @click:append="changeMobile"
                              @keyup.enter.native="handleMobileEnter"
                              :rules="mobileRules"
                              label="Enter Mobile Number"
                              required
                      ></v-text-field>



                      <v-text-field
                              v-if="otpSent"
                              ref="otpField"
                              v-model="otp"
                              prepend-icon="fa-lock"
                              :append-icon="!otpSending && !loading ? 'fa-refresh' : ''"
                              @click:append="resendOTP"
                              :rules="otpRules"
                              :loading="otpSending"
                              label="Enter OTP"
                              required
                      ></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>

                    <v-btn
                            v-if="!otpSent"
                            large
                            block
                            :disabled="!($refs.mobileField && $refs.mobileField.valid)"
                            color="primary"
                            @click="sendOTP"
                            :loading="otpSending"
                    >Click here to get OTP</v-btn>

                    <v-btn
                            v-if="otpSent"
                            class="text-capitalize"
                            large
                            block
                            color="primary"
                            @click="validateOTP"
                            :disabled="otpSending"
                            :loading="loading"
                    >Confirm OTP</v-btn>


                  </v-card-actions>

                  <v-banner>
                    <p class="">
                      By signing in you agree to our <a href="/terms" style="color: #536DFE" target="_blank" >terms and conditions</a>
                    </p>
                  </v-banner>
                </v-card>
               </div>
            </v-col>

            <v-col cols="12" class="d-flex justify-center">
              <v-footer>
                <div class="primary--text">© 2024 Sai Silks (Kalamandir) Ltd. All rights reserved.</div>
              </v-footer>
            </v-col>

          </v-row>
        </v-col>
      </v-row>

    </v-container>
  </v-app>
</template>

<script>
import defaultSettings from '@/settings'
  export default {
    name: 'Login',
    data() {
      return {
        loading:false,
        otpSending:false,
        otpSent: false,
        mobile:'',
        mobileRules:[
          v => !!v || 'Mobile Number is required',
          v => /^[6789]\d{9}$/.test(v) || 'Mobile Number is invalid',
        ],
        otp:'',
        otpRules: [
          v => !!v || 'OTP is required',
        ],
      }
    },
    computed:{
      isMobileFieldValid(){
        return this.$refs.mobileField && this.$refs.mobileField.valid;
      },
      headerTitle() {
        return defaultSettings.title
      }

    },
    methods: {

      handleMobileEnter(){
        if(this.isMobileFieldValid){
          this.sendOTP();
        }
      },

      changeMobile(){

        this.otpSent = false;
        this.$refs.mobileField.focus()
      },

      sendOTP(){

        this.otpSending = true;
        this.$store.dispatch('customer/sendOTP', {mobile:this.mobile}).then((resp) => {

            this.otpSent = true;
            this.otpSending = false;
            const response = resp.apiData;
            if(response.sms_sent){

              this.otpSent = true;
              this.$message({
                message: 'An OTP has been sent to your Mobile Number.',
                type: 'success'
              });

              this.$nextTick(() => {

                this.$refs.otpField.focus()
              });  
            }else{

              this.$message({
                message: 'Could not send SMS, Please try again later.',
                type: 'error'
              });

            }
        }).catch(() => { this.otpSending = false;})

      },
      resendOTP(){

        this.$nextTick(() => {

          !this.otpSending && this.sendOTP()
        });

      },
      validateOTP(){

        const valid = this.$refs.form.validate();
        if(valid){

          this.loading = true;
          this.$store.dispatch('customer/confirmOTP', {mobile:this.mobile, otp:this.otp}).then(() => {
            this.$router.push({ path: '/' })
          }).catch(() => { this.loading = false;})
        }

      },
    },
  }

</script>

<style src="./Login.scss" lang="scss"/>
